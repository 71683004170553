import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = ["| 신한 특화 요금제 출시 이후 구독 서비스 모델 구상 계획"]

const content = () => (
  <div>
    <br />
    <div className="descImage promotion">
      <div>
        <img src="/images/press/10/yxzwAyym.jpg" alt="Figure1" />
      </div>
      <div className="desc">
        △ ㈜스테이지파이브가 6일(화) 신한은행과 ‘금융과 통신을 결합한 신규 서비스 제공을 위한 MOU’를 체결했다. 오른쪽부터
        스테이지파이브 이제욱 공동대표, 신한은행 조경선 부행장
      </div>
    </div>
    <p>
      <br />
      ㈜스테이지파이브(공동대표 이제욱 ㆍ 서상원, 이하 스테이지파이브)가 6일(화) 신한은행(행장 진옥동)과 함께 통신 및 금융이
      결합된 새로운 형태의 서비스를 제공하기 위하여 전략적 업무협약(MOU)을 체결했다.
      <br />
      <br />
      양 사는 MOU 이후 통신 및 금융이 결합된 특화 요금제를 출시하고, 구독 서비스 형태의 새로운 유저 중심형 서비스를 개발할
      예정이다.
      <br />
      <br />
      기존 통신사에서 요금제와 단말이 결합된 상품을 개발하여 판매에 주력하는 것과 달리, 특정 유형별 이용자가 필요로 하는 서비스를
      맞춤형으로 설계하고 제공하는 데 초점을 맞출 예정이다.
      <br />
      <br />
      스테이지파이브는 이번 제휴를 통해 신한금융그룹의 대표 금융 플랫폼인 신한플러스와 함께 ▲신한특화 요금제 등 금융, 통신이
      결합된 새로운 형태 서비스 개발 ▲ 신한은행 20대전용 브랜드 ‘헤이영’ 등을 활용한 MZ세대 공동마케팅 진행 ▲구독 경제 서비스 등
      신규 비즈니스 모델 발굴 등을 추진할 계획이다.
      <br />
      <br />
      해당 서비스는 신한은행 산하 다양한 어플리케이션과 스테이지파이브의 통신판매플랫폼 ‘핀다이렉트샵’에서 올 상반기 내 개발
      단계를 거쳐 시중에 선보이게 된다.
      <br />
      <br />
      양 사는 이번 MOU를 통해 ‘통신’과 ‘금융’이라는 생활밀착형 산업분야가 융합함으로써 이용자 편의를 배가하는 것이 목표이다.
      <br />
      <br />
      스테이지파이브 이제욱 대표는 “차세대 통신 사업을 펼쳐온 스테이지파이브가 신한은행이라는 든든한 동지를 만났다”며, “통신과
      금융이 결합된, 새로우면서도 이 시대에 꼭 필요한 유저 중심형 결합 서비스를 선보일 수 있도록 박차를 가할 것”이라고 전했다.
      <br />
      <br />
      신한플러스 조경선 부행장은 “이번 업무 협약 체결로 금융과 통신을 결합한 다양하고 신선한 비즈니스 모델 발굴을 통해 양사의
      고객들이 더 많은 편의와 혜택을 누릴 수 있게 되길 기대한다”며 “앞으로도 다양한 제휴를 통해 고객들에게 새로운 경험과 차별적
      서비스를 제공하겠다”고 밝혔다.
      <br />
      <br />
      스테이지파이브는 2017년 카카오 공동체에 편입돼 카카오 서비스 기반 차세대 통신사업을 펼쳐온 기업이다. 2020년 8월 ICT
      규제샌드박스 사업을 개시하여 비대면 통신 플랫폼 ‘핀다이렉트샵’을 구축, 운영하고 있다. 또한 AI 반려로봇, 미러북 등 통신 기반
      IoT 디바이스를 선보이며 통신 산업 저변을 확대시키는 중이다. 최근에는 통신 이용자의 접점을 강화하고자 온라인 요금제를
      출시하는 한편, 카카오톡 등 온라인 통신 가입이 가능한 ‘핀다이렉트샵’으로 통신 이용자의 선택권을 강화한 바 있다. 해당 플랫폼은
      지난 3월 기준 누적 방문자 수 20만을 돌파했다.
      <br />
      <br />
      1,500만 고객을 보유한 신한플러스는 작년 4월 멤버십 서비스 출시를 통해 신한금융그룹의 금융 혜택 뿐 아니라 쿠폰, 포인트 등
      다양한 비금융 혜택을 고객에게 제공하고 있다.
      <br />
      <br />
    </p>
  </div>
)

const press10 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 4월 6일 즉시 배포 가능"
      pageInfo="*총 2매/ 첨부: 사진 1매(신한은행 제공)"
      title="‘통신’과 ‘금융’의 의기투합, “유저 중심형 서비스 제공할 것”<br />스테이지파이브ㆍ신한은행, 전략적 업무협약(MOU) 체결"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press10
